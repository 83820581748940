import React from 'react';

const Contact = () => {

    return (
        <div className="contact" id="contact">
            <div className="contact__container" data-aos="zoom-in" data-aos-delay="100">
                <div className="section__label">— Contact</div>
                <div className="contact__subheading">Let's get in touch!</div>
                {/* <div className="contact__description">I'm currently open to full time opportunities, and would love to hear from you!</div> */}
                <a className="btn btn__primary btn__primary--outline" href="mailto:donaldtang08@gmail.com" target="_blank" rel="noreferrer">Contact Me</a>
            </div>
        </div>
    )
};

export default Contact;

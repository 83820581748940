import React from 'react';

const Footer = () => {

    return (
        <div className="footer__container">
            <footer className="footer__nav">
                <div className="footer__logo"><span className="footer__logo--bold">DONALD</span> TANG</div>
                <div className="footer__socials">
                    <a className="icons__item" href="https://github.com/turazi" target="_blank" rel="noreferrer"><i class="fab fa-github"></i></a>
                    <a className="icons__item" href="https://www.linkedin.com/in/donaldtang8/" target="_blank" rel="noreferrer"><i class="fab fa-linkedin"></i></a>
                    <a className="icons__item" href="mailto:donaldtang08@gmail.com" target="_blank" rel="noreferrer"><i class="fas fa-envelope"></i></a>
                </div>
            </footer>
        </div>
    )
};

export default Footer;
import React, { useState, useEffect } from 'react';

const Header = () => {
    const [scrolling, setScrolling] = useState(false);

    const listenScrollEvent = () => {
        if (window.scrollY < 25) {
            setScrolling(false);
        } else {
            setScrolling(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
    }, []);

    const [open, setOpen] = useState(false);

    const toggleMobileNav = () => {
        setOpen(!open);
    }

    return (
        <header className={scrolling ? "header__container header__container--scroll" : "header__container"}>
            <div className="header__logo"><span className="xl-bold">DONALD</span> TANG</div>
            <nav className="header__nav">
                <a
                    href="#"
                    className="header__nav--item"
                >
                    Home
                </a>
                <a
                    href="#about"
                    className="header__nav--item"
                >
                    About Me
                </a>
                <a
                    href="#projects"
                    className="header__nav--item"
                >
                    Works
                </a>
                <a
                    href="#contact"
                    className="header__nav--item"
                >
                    Contact
                </a>
                <div className="header__nav--socials">
                    <a className="icons__item" href="https://github.com/donaldtang8" target="_blank" rel="noreferrer"><i class="fab fa-github fa-3x"></i></a>
                    <a className="icons__item" href="https://www.linkedin.com/in/donaldtang8/" target="_blank" rel="noreferrer"><i class="fab fa-linkedin fa-3x"></i></a>
                    <a className="icons__item" href="mailto:donaldtang08@gmail.com" target="_blank" rel="noreferrer"><i class="fas fa-envelope fa-3x"></i></a>
                </div>
            </nav>
            
            <div className={open ? "mobile__nav mobile__nav--open" : "mobile__nav"} onClick={toggleMobileNav}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            
            <nav className={open ? "mobile__sidebar mobile__sidebar--open" : "mobile__sidebar"}>
                <div className="mobile__sidebar--menu">
                    <a
                        href="#"
                        className="mobile__nav--item"
                    >
                        Home
                    </a>
                    <a
                        href="#about"
                        className="mobile__nav--item"
                    >
                        About Me
                    </a>
                    <a
                        href="#projects"
                        className="mobile__nav--item"
                    >
                        Works
                    </a>
                    <a
                        href="#contact"
                        className="mobile__nav--item"
                    >
                        Contact
                    </a>
                </div>
                <div className="mobile__nav--socials">
                    <a className="icons__item" href="https://github.com/turazi" target="_blank" rel="noreferrer"><i className="fab fa-github"></i></a>
                    <a className="icons__item" href="https://www.linkedin.com/in/donaldtang8/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin"></i></a>
                    <a className="icons__item" href="mailto:donaldtang08@gmail.com" target="_blank" rel="noreferrer"><i className="fas fa-envelope"></i></a>
                </div>
            </nav>
        </header>
    )
};

export default Header;

import logo from './logo.svg';
import './App.css';

/* Components */
import Header from './components/header/header';
import Jumbotron from './components/jumbotron/jumbrotron';
import Biography from './components/biography/biography';
import Projects from './components/projects/projects';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';

const App = () => {
  return (
    <div className="App">
      <Header />
      <Jumbotron />
      <Biography />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';
import Pdf from "./../../assets/Resume.pdf";

const Jumbotron = () => {
    return (
        <div className="jumbotron__section">
            <div className="jumbotron__container">
                <div className="jumbotron__label" data-aos="zoom-in" data-aos-delay="100">Software Engineer</div>
                <div className="jumbotron__intro" data-aos="zoom-in" data-aos-delay="200">Hello, I'm</div>
                <div className="jumbotron__heading-1 x-bold" data-aos="zoom-in" data-aos-delay="300">Donald Tang.</div>
                <div className="jumbotron__heading-2 x-bold" data-aos="zoom-in" data-aos-delay="400">I build web-based applications to solve problems.</div>
                <div className="strike"></div>
                <div className="jumbotron__subheading bold" data-aos="zoom-in" data-aos-delay="500">I'm a software engineer based in New York City specialized in developing scalable web applications across the full stack.</div>
                <a
                    className="btn btn__primary btn__primary--outline"
                    href={Pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Résumé
                </a>
            </div>
        </div>
    )
};

export default Jumbotron;

import React from 'react';
import ProjectData from './../../data/projectData.json';

const Projects = () => {
    const projData = JSON.parse(JSON.stringify(ProjectData));

    return (
        <div className="projects__section" id="projects">
            <div className="projects__container" data-aos="zoom-in" data-aos-delay="200">
                <div className="section__label">— Projects</div>
                <div className="projects__description">Here are some things I've worked on:</div>
                <div className="projects__list">
                    {projData.map((project, index) => {
                        return <div key={index} className="projects__item">
                                    <div className="projects__item--name">{project.name}</div>
                                    <div className="projects__item--description">{project.description}</div>
                                    <a className="projects__icon icons__item" href={project.source} target="_blank" rel="noreferrer" title="View Source"><i class="fab fa-github"></i></a>
                                </div>
                    })}
                </div>
            </div>
        </div>
    )
};

export default Projects;
